import React from "react";
import "./App.css";
import { ReactComponent as IconFacebook } from "./assets/icons/facebook.svg";
import { ReactComponent as IconTwitter } from "./assets/icons/twitter.svg";
import logo2 from "./assets/logo.svg";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src={logo2} height="80" />
          </div>
          <div className="social">
            <a
              href="https://www.facebook.com/QurfyApp-103108722418027"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconFacebook className="icon" />
            </a>
            <a
              href="https://twitter.com/QurfyApp"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>
              Website coming soon. Please check back to know more. Shoot us an
              email if you're curious.
            </p>
          </div>
          <a href="mailto:contact@qurfy.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span>@ SR Med Tech Solutions Private Limited</span>
        </div>
      </div>
    );
  };
}

export default App;
